"use client";

import useCookiesConsent from '@/components/basic/CookiesConsent/useCookiesConsent';
import useClientContext from '@/context/ClientContext';
import Button from '@/controls/Button';
import styles from './styles.module.scss';

const CookiesConsent = () => {
  const { showCookies, onAcceptAll, onAcceptNecessary } = useCookiesConsent();
  const { translate } = useClientContext();
  if (!showCookies) {
    return null;
  }
  return (
    <div className={styles.CookiesConsent}>
      <p className={styles.title}>{translate("This site uses cookies")}</p>
      <p className={styles.text}>
        {translate("To ensure the correct functioning of the site and improve your user experience, the Mallprix site uses cookies. You can agree to the use of all cookies or select only the necessary ones.")}
      </p>
      <div className={styles.buttonsWrapper}>
        <Button color="primary" onClick={onAcceptAll}>{translate("Accept all")}</Button>
        <Button color="secondary" variant="outlined" onClick={onAcceptNecessary}>{translate("Accept necessary")}</Button>
      </div>
    </div>
  )
}

export default CookiesConsent;
