const defaultArgs = {
  text: "Undefined text",
  onSuccess: () => {},
  onFailure: () => {},
};

interface CopyText {
  text: string;
  onSuccess?: () => void;
  onFailure?: () => void;
}

const copyText = (args: CopyText) => {
  const { text, onFailure, onSuccess } = Object.assign(defaultArgs, args);
  navigator.clipboard.writeText(text).then(onSuccess, onFailure);
};

export default copyText;
