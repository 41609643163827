"use client";

import useClientContext from '@/context/ClientContext';
import React, {useCallback, useMemo} from 'react';
import Select from '@/controls/Select';
import PublicIcons from '@/components/basic/PublicIcon';
import styles from "./styles.module.scss";
import {useDataPublicContactsInfo} from '@/modules/public/hooks/useData';
import copyText from '@/utils/copyText';
import cn from "@/libs/cn";

interface SelectContactProps {}

const OPERATORS = ['kyivstar', 'vodafone', 'lifecell'];

function formatPhoneNumber(phoneNumber: string) {
  const cleanNumber = phoneNumber.replace("+38", "");
  return cleanNumber.replace(/(\d{3})(\d{3})(\d{2})(\d{2})/, "($1) $2-$3-$4");
}

const SelectContact = (props: SelectContactProps) => {
  const { translate, locale, isMacOS } = useClientContext();
  const contactsInfoData = useDataPublicContactsInfo(locale);
  const contactsInfo = contactsInfoData?.data || {};
  const phoneNumbersObj = useMemo(() => {
    return contactsInfo?.phone_numbers?.reduce((result, phoneNumber) => {
      result[phoneNumber.operator] = phoneNumber.phone_number;
      return result;
    }, {} as any) || {};
  }, [contactsInfo?.phone_numbers]);

  const phoneNumbersComponents = useMemo(() => {
    return OPERATORS.map((operator) => {
      if(phoneNumbersObj[operator]) {
        const phoneNumber = phoneNumbersObj[operator];
        const displayPhoneNumber = formatPhoneNumber(phoneNumber);
        return (
          <div
            key={operator}
            // href={`tel:${phoneNumber}`}
            className={cn(styles.contact, styles.phoneNumber, isMacOS && styles.macOSPaddings)}
            onClick={() => copyText({ text: displayPhoneNumber })}
          >
            <PublicIcons
              name={operator as any}
              width={20}
              height={20}
            />
            <span>{displayPhoneNumber}</span>
          </div>
        )
      } else {
        return null;
      }
    }).filter((elem) => !!elem);
  }, [phoneNumbersObj]);

  const content = useMemo(() => {
    return (
      <div className={styles.ContactsWrapper}>
        {phoneNumbersComponents}
        {contactsInfo?.messengers?.telegram && (
          <a
            className={cn(styles.contact, styles.link, isMacOS && styles.macOSPaddings)}
            href={`https://t.me/${contactsInfo?.messengers?.telegram}`}
            target="_blank"
          >
            <PublicIcons name="telegramColor" width={20} height={20} />
            <span>Telegram</span>
          </a>
        )}
        {contactsInfo?.messengers?.viber && (
          <a
            className={cn(styles.contact, styles.link, isMacOS && styles.macOSPaddings)}
            href={`viber://chat?number=${encodeURIComponent(contactsInfo?.messengers?.viber)}`}
            target="_blank"
          >
            <PublicIcons name="viberColor" width={20} height={20} />
            <span>Viber</span>
          </a>
        )}
        <div className={styles.workTimeWrapper}>
          <div>{translate("Help center works")}:</div>
          {contactsInfo?.work_time?.map((item) => (
            <div
              className={styles.workTime}
              key={item.day}
            >
              {item.day.split('-').map((part) => translate(part)).join('-')}: {translate(item.time)}
            </div>
          ))}
        </div>
      </div>
    )
  }, [contactsInfo?.messengers?.telegram, contactsInfo?.messengers?.viber, contactsInfo?.work_time, phoneNumbersComponents, translate]);


  return (
    <Select
      className={styles.SelectContact}
      content={content}
      options={[]}
      onChange={() => {}}
      classNameOptions={styles.SelectBackdrop}
    >
      <>
        <div className={styles.label}>{translate("Contacts")}</div>
        <PublicIcons name="arrowCaretDown" height={18} width={18} data-arrow="icon-arrow" />
      </>
    </Select>
  );
};

export default SelectContact;
