export function getIdsFromSlug(slug: string) {
    const arr = `${slug}`.split("_");
    if (arr.length === 2) {
        // It is mono product
        return { productId: arr[1], variantId: null };
    } else if (arr.length === 3) {
        // It is product with variants
        return { productId: arr[1], variantId: arr[2] };
    }
    console.error("Error determining IDs from slug", slug);
    return { productId: null, variantId: null };
}
